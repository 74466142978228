// Import css
import "../scss/styles.scss";

// Import our functions
const imports = {
    applyLoaded: false,
    heroLoaded: false,

    hero: () => {
      return import("./gsap/hero")
          .then(({default: hero}) => {
              /**
               * Videos are loaded by default, to prevent the video from being downloaded
               * on mobile devices, we need remove the source and poster attributes from
               * the html and add them back in via javascript when the module is loaded.
               * Preferably, on tablet and desktop devices.
               *
               * @type {HTMLElement}
               */
              let video = document.getElementById("hero--video");
              let source = video.getElementsByTagName('source');

              source[0].src = video.dataset.src;
              video.poster = video.dataset.poster;

              video.load();
              hero();
          })
          .catch((error) => {
              console.log(error);
          })
    },

    h1: () => {
        return import("./gsap/h1")
            .then(({default: h1}) => {
                h1();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    headline: () => {
        return import("./gsap/headline")
            .then(({default: headline}) => {
                headline();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    cohort: () => {
        return import("./gsap/cohort")
            .then(({default: cohort}) => {
                cohort();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    numbers: () => {
        return import("./gsap/numbers")
            .then(({default: numbers}) => {
                numbers();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    apply: () => {
        return import("./gsap/apply")
            .then(({default: apply}) => {
                let figures = document.getElementsByClassName('apply-background__image');
                let stage = document.getElementById('stage');

                figures[0].loading = 'eager';
                stage.style.display = 'block';
                apply();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    news: () => {
        return import("./gsap/news")
            .then(({default: news}) => {
                news();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    testimonial: () => {
        return import("./gsap/testimonial")
            .then(({default: testimonial}) => {
                testimonial();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    socials: () => {
        return import("./gsap/socials")
            .then(({default: socials}) => {
                socials();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    fab: () => {
        return import("./gsap/fab")
            .then(({default: fab}) => {
                fab();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    rfi: () => {
        return import("./gsap/rfi")
            .then(({default: rfi}) => {
                rfi();
            })
            .catch((error) => {
                console.log(error);
            })
    },

    loadAssets: () => {
        imports.h1();
        imports.socials();

        /**
         * Only run the animations if the user has not requested reduced motion
         */
        if(!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            imports.headline();
            imports.cohort();
            imports.numbers();
            imports.news();
            imports.testimonial();
            imports.rfi();
            imports.fab();
        }

        /**
         * Only run the animations if the user has not requested reduced motion, and it is at least a tablet size
         */
        if(window.matchMedia("(min-width: 768px)").matches) {
            imports.hero();
            imports.heroLoaded = true;

            if(window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
                let heroVideo = document.getElementById("hero--video");
                heroVideo.pause();
            }
        }

        /**
         * Run apply animation if at least laptop size
         */
        if(!window.matchMedia("(prefers-reduced-motion: reduce)").matches && window.matchMedia("(min-width: 992px)").matches) {
            imports.applyLoaded = true;
            imports.apply();
        }
        else {
            // change cursor to pointer
            let apply = document.getElementById("apply");
            apply.style.setProperty('--cursor', 'pointer');

            // hide the canvas
            let canvas = document.getElementById("stage");
            canvas.style.display = "none";
        }
    }
};


// Run our functions
((i) => {
    i.loadAssets();

    screen.orientation.addEventListener('change', () => {
        if(!i.applyLoaded && !window.matchMedia("(prefers-reduced-motion: reduce)").matches && window.matchMedia("(min-width: 992px)").matches) {
            i.applyLoaded = true;
            i.apply();
        }

        if(!i.heroLoaded && window.matchMedia("(min-width: 768px)").matches) {
            i.heroLoaded = true;
            i.hero();
        }
    });

    window.addEventListener('resize', () => {
        if(!i.applyLoaded && !window.matchMedia("(prefers-reduced-motion: reduce)").matches && window.matchMedia("(min-width: 992px)").matches) {
            i.applyLoaded = true;
            i.apply();
        }

        if(!i.heroLoaded && window.matchMedia("(min-width: 768px)").matches) {
            i.heroLoaded = true;
            i.hero();
        }
    });
})(imports)